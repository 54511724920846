import { clsx } from 'clsx';
import { useInput } from 'react-admin';
import { RcFile } from 'antd/lib/upload';

import { CxCredit } from '@types';
import { s3http } from '@network';
import { FileControlled, FileUpload, InitialCard } from '@components';
import cls from './ESGDocs.module.css';

interface Props {
  cxCredit: CxCredit;
}

interface FileProps {
  id: number;
  adminUrl: string;
  ext: string;
}

interface FCompProps {
  file: FileProps | null;
  setFile: React.Dispatch<React.SetStateAction<FileProps | null>>;
  name: string;
}

const File = ({ file, setFile, name }: FCompProps) => {
  const uploadAction = async (rcFile: RcFile, setFile: any): Promise<string> => {
    const name = rcFile.name.split('.');
    const ext = name.length ? name[name.length - 1] : 'jpeg';
    const { id, adminUrl } = await s3http.uploadFile(rcFile as File);
    setFile({ id, adminUrl, ext });

    return adminUrl;
  };

  return file ? (
    <FileControlled
      wrapClass={clsx(cls.filePreview, file.ext === 'pdf' && cls.filePdf)}
      adminUrl={file.adminUrl}
      isPdf={file.ext === 'pdf'}
      downloadFilename={`${name}.${file.ext}`}
      onDelete={() => setFile(null)}
      zoom
    />
  ) : (
    <FileUpload
      uploadAction={f => uploadAction(f, setFile)}
      className={cls.uploadBtn}
      wrapperClassName={cls.uploader}
    />
  );
};

export const ESGDocs = ({ cxCredit }: Props) => {
  const { field: { value: soilFile, onChange: changeSoilFile } } = useInput({ source: 'cx.soilFile' });
  const { field: { value: orgFile, onChange: changeOrgFile } } = useInput({ source: 'cx.orgFile' });

  return (
    <InitialCard
      left="ESG Docs"
      contentClass={cls.content}
      wrapperClass={cls.cardWrapper}
    >
      <div className={cls.wrap}>
        <div className={cls.row}>
          <div className={cls.col}>
            <div className={cls.fileBlock}>
              <File file={soilFile} setFile={changeSoilFile} name={`Cx_${cxCredit.id}_soilAnalysis`} />
            </div>
            <div className={cls.title}>Soil Analysis</div>
          </div>
          <div className={cls.col}>
            <div className={cls.fileBlock}>
              <File file={orgFile} setFile={changeOrgFile} name={`Cx_${cxCredit.id}_orgCertificate`} />
            </div>
            <div className={cls.title}>Org. Certificate</div>
          </div>
        </div>
      </div>
    </InitialCard>
  );
};
