import { Http } from './http';
import { Product } from '@types';

class ProductHttp extends Http {
  prefix = 'product';

  async getListWithoutLimit(): Promise<Product[]> {
    const res = await this.get<Product[]>('/all');

    return res.data;
  }

  async update(productId: number, values: {[key: string]: any}) {
    await this.put(`/${productId}`, values);
  }

  async create(params: CreateProductParams): Promise<Product> {
    const res = await this.post<Product>('/external', params);

    return res.data;
  }

  async updateBulk(params: UpdateBulkParams): Promise<number> {
    const { data } = await this.put<number>('/update-bulk', params);

    return data;
  }
}

export const productHttp = new ProductHttp();

export interface CreateProductParams {
  name: string;
  brandId: number;
  categoryId: number;
  subcategoryId: number;
  externalShop: string;
  description?: string | null;
  pdfLink?: string | null;
  image?: string;
}

interface UpdateBulkParams {
  selectedIds: number[];
  prop: string;
  value: string | number | null;
}


