import { FunctionField, FunctionFieldProps } from 'react-admin';
import Chip from '@mui/material/Chip';
import { Color } from '@mui/material';
import { resolvePath } from '@components';

export interface ColoredOption {
  id: string;
  name: string;
  color: Color | string;
}

export const options: ColoredOption[] = [
  { id: 'Pending', name: 'Pending', color: '#FD492D' },
  { id: 'Done', name: 'Done', color: '#88D256' },
];

interface Props {
  source: string;
  className?: string;
  width?: number;
  badgeMode?: boolean;
}

export const DonePendingField = (props: Props & Partial<FunctionFieldProps>) => {
  const { className, width, badgeMode, ...restProps } = props;

  return (
    <FunctionField {...restProps} render={(record: any) => {
      const isChecked = resolvePath(record, props.source || '') ?? false;
      const status = isChecked ? 'Done' : 'Pending';
      const statusName = options.find(o => o.id === status)?.name;
      const color: any = options.find(o => o.id === status)?.color;
      if (!status || !color) return null;

      return (
        <Chip
          label={statusName || status}
          size="small"
          style={{
            backgroundColor: typeof color === 'string'
              ? color
              : (color && color[500] ? color[500] : '#74706B'),
            color: 'white',
            height: 20,
            fontSize: 11,
            width: width || '100%',
          }}
          className={className}
        />
      );
    }} />
  );
};
