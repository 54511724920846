import { clsx } from 'clsx';
import { RightCircleFilled } from '@ant-design/icons';

import { CxCredit } from '@types';
import { InitialCard } from '@components';
import { dateFormatter, querySerialize } from '@utils';
import cls from './Tasks.module.css';

interface Props {
  cxCredit: CxCredit;
}

const AddBtn = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34" height="34" viewBox="0 0 34 34" fill="none"
  >
    <g filter="url(#filter0_d_3965_24438)">
      <rect x="4" width="26" height="26" rx="4" fill="#042E6B"/>
      <rect x="15" y="5" width="4" height="16" rx="1.5" fill="white"/>
      <rect x="25" y="11" width="4" height="16" rx="1.5" transform="rotate(90 25 11)" fill="white"/>
    </g>
    <defs>
      <filter id="filter0_d_3965_24438" x="0" y="0" width="34" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3965_24438"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3965_24438" result="shape"/>
      </filter>
    </defs>
  </svg>
);

const Status = ({ status }: { status: string | null; }) => !status ? (
  <div className={cls.status}>---</div>
) : (
  <div className={clsx(cls.status, status)}>
    {status[0].toUpperCase()}{status.slice(1, status.length)}
  </div>
);

export const Tasks = ({ cxCredit }: Props) => {
  const listUrl = `/credit/${cxCredit.id}?calls`;
  const createUrl = '/#/credit-call/create?' + querySerialize({ creditId: cxCredit.id });

  const onCreate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(createUrl, '_blank');
  };

  return (
    <InitialCard
      left="Tasks"
      right={<RightCircleFilled className={cls.headerIcon} />}
      link={listUrl}
      contentClass={cls.content}
      wrapperClass={cls.cardWrapper}
    >
      <div className={cls.wrap}>
        <div className={cls.title}>Most Recent Overdue Call</div>
        {cxCredit.cx?.callsOverdue?.length ? (
          <div className={cls.lastCall}>
            <div className={cls.callTop}>
              <div className={cls.callTitle}>{cxCredit.cx.callsOverdue[0].title}</div>
              <div className={cls.callStatus}>
                <Status status={cxCredit.cx.callsOverdue[0].status} />
              </div>
            </div>
            <div className={cls.callBottom}>
              <div className={cls.callTitle}>
                Contact by {cxCredit.cx.callsOverdue[0].contactBy
                  ? dateFormatter.toDateSlash(cxCredit.cx.callsOverdue[0].contactBy)
                  : '---'}
              </div>
              <div className={cls.callStatus}>
                <Status status={cxCredit.cx.callsOverdue[0].risk} />
              </div>
            </div>
          </div>
        ) : (<div className={cls.empty}>Calls list is empty</div>)}
        <div className={cls.actions}>
          <div className={clsx(cls.action, cls.actionLeft)}>
            <div className={cls.actionTitle}>Pending Calls</div>
            <div className={cls.actionCounter}>{cxCredit?.cx?.callsOverdue?.length || 0} Calls</div>
          </div>
          <div className={clsx(cls.action, cls.actionRight)} onClick={onCreate}>
            <AddBtn />
          </div>
        </div>
      </div>
    </InitialCard>
  );
};
