import { Http } from './http';
import { Credit } from '@types';

class ReportsHttp extends Http {

  prefix = 'reports';

  async creditsLastModification(params: RangeParams): Promise<Credit[]> {
    const res = await this.get<Credit[]>('/credits-last-modification', { params });

    return res.data;
  }

  async creditsLastCompleted(params: RangeParams): Promise<Credit[]> {
    const res = await this.get<Credit[]>('/credits-last-completed', { params });

    return res.data;
  }

  async parCredits(params: RangeParams): Promise<ParCredit[]> {
    const res = await this.get<ParCredit[]>('/par-credits', { params });

    return res.data;
  }

  async generalNumbers(params: RangeParams): Promise<Record<string, number>> {
    const res = await this.get<Record<string, number>>('/general-numbers', { params });

    return res.data;
  }

  async generalNumbersGraph(params: RangeTypeParams): Promise<any[]> {
    const res = await this.get<any[]>('/general-numbers-graph', { params });

    return res.data;
  }

  async creditsStatuses(params: RangeTypeParams): Promise<Record<string, string| number>> {
    const res = await this.get<Record<string, string | number>>('/credits-statuses', { params });

    return res.data;
  }

  async adminLoginCounter(params: RangeTypeParams): Promise<AdminLoginCounter> {
    const res = await this.get<AdminLoginCounter>('/admin-login-counter', { params });

    return res.data;
  }

  async callsReport(params: RangeParams): Promise<CallsReportNumbers> {
    const res = await this.get<CallsReportNumbers>('/calls-report', { params });

    return res.data;
  }

  async reachToWomen(params: RangeParams): Promise<ReachToWomenData> {
    const res = await this.get<ReachToWomenData>('/reach-to-women', { params });

    return res.data;
  }

  async genderReport(params: RangeParams): Promise<GenderReportData> {
    const res = await this.get<GenderReportData>('/gender-report', { params });

    return res.data;
  }

  async userCrops(params: RangeParams & { detailed?: boolean }): Promise<PieReport[]> {
    const res = await this.get<PieReport[]>('/user-crops', { params });

    return res.data;
  }

  async creditCrops(params: RangeParams & { detailed?: boolean }): Promise<PieReport[]> {
    const res = await this.get<PieReport[]>('/credit-crops', { params });

    return res.data;
  }

  async paidProducts(params: PaidReportsParams): Promise<PaidReportsProps> {
    const res = await this.get<PaidReportsProps>('/paid-products', { params });

    return res.data;
  }

  async contactUsReport(params: RangeParams): Promise<ContactUsData> {
    const res = await this.get<ContactUsData>('/contact-us-report', { params });

    return res.data;
  }

  async registerCreditProgress(params: RangeTypeParams): Promise<RegisterCreditProgress[]> {
    const res = await this.get<RegisterCreditProgress[]>('/register-credit-progress', { params });

    return res.data;
  }

  async allocationReport(params: RangeTypeParams): Promise<AllocationGoalReport[]> {
    const res = await this.get<AllocationGoalReport[]>('/allocation-report', { params });

    return res.data;
  }

  async visitorsReport(params: RangeTypeParams): Promise<VisitorReport[]> {
    const res = await this.get<VisitorReport[]>('/visitors-report', { params });

    return res.data;
  }

  async newRegistrationsReport(params: RangeTypeParams): Promise<NewRegistrationReport[]> {
    const res = await this.get<NewRegistrationReport[]>('/new-registrations-report', { params });

    return res.data;
  }

  async financialRankingReport(params: RangeParams): Promise<FinancialRankingReport[]> {
    const res = await this.get<FinancialRankingReport[]>('/financial-ranking-report', { params });

    return res.data;
  }

  async investorReport(params: RangeParams): Promise<InvestorReports> {
    const res = await this.get<InvestorReports>('/investor-reports', { params });

    return res.data;
  }

  async userSourceReport(): Promise<SourceReportData> {
    const res = await this.get<SourceReportData>('/user-source-report');

    return res.data;
  }

  async creditSourceReport(): Promise<SourceReportData> {
    const res = await this.get<SourceReportData>('/signed-credit-source-report');

    return res.data;
  }
}

export interface PaidReportProps {
  positionId: number;
  brand: string;
  name: string;
  qty: number;
  qtyPercent: number;
  sumPercent: number;
  totalCost: number | string;
}

export interface PaidReportsProduct {
  brand: string;
  category: string;
  id: number;
  image: string;
  product: string;
  qty: number;
  subcategory: string;
}

export interface PaidReportsProps {
  categoryReport: PaidReportProps[];
  subcategoryReport: PaidReportProps[];
  brandReport: PaidReportProps[];
  products: PaidReportsProduct[];
  partnersAutocomplete: string[];
  usersAutocomplete: string[];
  credits: { id: number; code: string }[];
  ordersCount: number;
  ordersLink: string;
  usersCount: number;
  usersLink: string;
}

export interface PaidReportsParams {
  partner?: string;
  user?: string;
  creditId?: string;
  sorted: 'Unit' | 'Price';
}

export interface RangeParams {
  range: string;
}

export interface RangeTypeParams {
  range: string;
  type: string;
  setLoader?: (num: number, value: boolean) => void;
}

export interface NewRegistrationReport {
  period: string;
  registered: number;
}

export interface VisitorReport {
  period: string;
  visited: number;
  registered: number;
  rate: number;
}

export interface RegisterCreditProgress {
  period: string;
  registered: number;
  applied: number;
  completed: number;
  appliedRegisteredRate: number;
  completedAppliedRate: number;
}

export interface AllocationGoalReport {
  period: string;
  allocation: number;
  credit: number;
}

export interface ReachToWomenData {
  registeredWomen: number;
  signedCredits: number;
  activeCredits: number;
}

export interface GenderReportData {
  usersGender: Record<string, number>;
  clientsGender: Record<string, number>;
}

export interface SourceReportData {
 sources: Record<string, number>;
}

export interface CallsReportNumbers {
  overdue: number;
  future: number;
}

export interface AdminLoginCounter {
  loginsByPeriod: number;
  logins: {period: string, loginTries: number}[];
}

export interface ContactUsData {
  opened: number;
  processing: number;
}

export interface PieReport {
  name: string;
  category: string;
  value: number;
  percent: number;
}

export interface FinancialRankingReport {
  label: string;
  value: number;
  count: number;
}

export interface FinancialRankingReportWithColors extends FinancialRankingReport{
  color: string;
}

export type ParCreditKey = 'PAR 30' | 'PAR 60' | 'PAR 90' | 'DELAY' | 'Recovered';

export interface ParCredit {
  key: ParCreditKey;
  total: number;
  amount: number;
}

export interface InvestorReports {
  registeredUsers: number;
  registeredUsersWomen: number;
  activeUsers: number;
  activeUsersWomen: number;
  activeCredits: number;
  activeCreditsWomen: number;
  partners: number;
  partnersWomen: number;
}

export const reportsHttp = new ReportsHttp();
