import { CxCredit } from '@types';
import { InitialCard } from '@components';
import cls from './Receipt.module.css';

interface Props {
  cxCredit: CxCredit;
}

const DownloadBtn = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="37" height="28" viewBox="0 0 37 28" fill="none">
        <g filter="url(#filter0_d_3965_24452)">
          <rect x="4" width="28.7805" height="20" rx="5" fill="#042E6B"/>
        </g>
        <path d="M17.2834 4.72766C17.2834 4.32579 17.6224 4 18.0407 4C18.4589 4 18.798 4.32579 18.798 4.72766V12.7319C18.798 13.1338 18.4589 13.4596 18.0407 13.4596C17.6224 13.4596 17.2834 13.1338 17.2834 12.7319V4.72766Z" fill="white"/>
        <path d="M14.2218 10.6997C13.9261 10.4155 13.9261 9.95476 14.2218 9.67059C14.5175 9.38642 14.997 9.38642 15.2928 9.67059L18.5056 12.7578C18.8013 13.042 18.8013 13.5027 18.5056 13.7869C18.2099 14.071 17.7304 14.071 17.4347 13.7869L14.2218 10.6997Z" fill="white"/>
        <path d="M21.7782 10.6997C22.0739 10.4155 22.0739 9.95476 21.7782 9.67059C21.4825 9.38642 21.003 9.38642 20.7072 9.67059L17.4944 12.7578C17.1987 13.042 17.1987 13.5027 17.4944 13.7869C17.7901 14.071 18.2696 14.071 18.5653 13.7869L21.7782 10.6997Z" fill="white"/>
        <rect x="10" y="15" width="17" height="2" rx="1" fill="white"/>
        <rect x="12" y="10" width="7" height="2" rx="1" transform="rotate(90 12 10)" fill="white"/>
        <rect x="27" y="10" width="7" height="2" rx="1" transform="rotate(90 27 10)" fill="white"/>
        <defs>
          <filter id="filter0_d_3965_24452" x="0" y="0" width="36.7805" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3965_24452"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3965_24452" result="shape"/>
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export const Receipt = ({ cxCredit }: Props) => {

  return (
    <InitialCard
      left="Receipt"
      contentClass={cls.content}
      wrapperClass={cls.cardWrapper}
    >
      <div className={cls.wrap}>
        <div className={cls.download}>Download</div>
        <div className={cls.update}>
          <div className={cls.updateTop}>Update</div>
          <div className={cls.updateBottom}>Last updated: DD/MM/YY</div>
        </div>
        <div className={cls.bottom}><DownloadBtn /></div>
      </div>
    </InitialCard>
  );
};
