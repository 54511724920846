import { clsx } from 'clsx';
import { useInput } from 'react-admin';

import { CxCreditQuestion } from '@types';
import { InitialCard, SelectInput, TextInput } from '@components';
import cls from './Questions.module.css';

interface Props {
  title: string;
  source: string;
}

interface QProps {
  item: CxCreditQuestion;
  idx: number;
  source: string;
}

const QuestionSelect = ({ idx, item, source }: QProps) => {
  return (
    <div className={cls.row}>
      <div className={clsx(cls.label, cls.labelSelect)}>{idx + 1}. {item.name}</div>
      <div className={clsx(cls.field, cls.fielSelect)}>
        <SelectInput
          source={`${source}[${idx}].answer`}
          label={false}
          choices={item.options.map(o => ({ id: o, name: o }))}
          className={cls.select}
        />
      </div>
    </div>
  );
};

const QuestionText = ({ idx, item, source }: QProps) => {
  return (
    <div className={cls.row}>
      <div className={clsx(cls.label, cls.labelText)}>{idx + 1}. {item.name}</div>
      <div className={clsx(cls.field, cls.fieldText)}>
        <TextInput
          source={`${source}[${idx}].answer`}
          label={false}
          className={cls.text}
          multiline
          rows={2}
          sx={{
            borderBottom: 'none',
            paddingBottom: '0',
            '& textarea': {
              lineHeight: '2em',
              backgroundImage: 'linear-gradient(to bottom, transparent 97%, #000 100%)',
              backgroundSize: '100% 2em',
            },
          }}
        />
      </div>
    </div>
  );
};

export const Questions = ({ title, source }: Props) => {
  const { field: { value } } = useInput({ source });

  return (
    <InitialCard
      left={title}
      contentClass={cls.content}
      wrapperClass={cls.cardWrapper}
    >
      <div className={cls.wrap}>
        {value?.length ? (
          value.filter((item: CxCreditQuestion) => item.isEnabled)
            .map((item: CxCreditQuestion, index: number) => (
              item.type !== 'text'
                ? <QuestionSelect key={index} idx={index} item={item} source={source} />
                : <QuestionText key={index} idx={index} item={item} source={source} />
            ))) : (
          <div className={cls.empty}>Empty</div>
        )}
      </div>
    </InitialCard>
  );
};
