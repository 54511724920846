import { InitialCard, TextAreaInput } from '@components';
import cls from './Comments.module.css';

export const Comments = () => {
  return (
    <InitialCard
      left="Comments and Progress"
      contentClass={cls.content}
      wrapperClass={cls.cardWrapper}
    >
      <div className={cls.wrap}>
        <TextAreaInput
          source="cropProblems"
          placeholder="Extra comments about the credit progress"
          className={cls.textarea}
          maxLength={400}
        />
      </div>
    </InitialCard>
  );
};
