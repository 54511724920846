import React from 'react';
import { Modal } from 'antd';
import { clsx } from 'clsx';
import { RightCircleFilled } from '@ant-design/icons';
import { IconButton } from '@mui/material';

import { CxCredit } from '@types';
import { InitialCard } from '@components';
import { cxHttp } from '@network';
import { useModalState } from '@hooks';
import { PolicyInsurances } from './PolicyInsurances';
import cls from './Insurance.module.css';

interface Props {
  cxCredit: CxCredit;
}

const Status = ({ status }: {status: 'complete' | 'pending'}) => (
  <div className={clsx(cls.status, status)}>
    {status[0].toUpperCase()}{status.slice(1, status.length)}
  </div>
);

export const Insurance = ({ cxCredit }: Props) => {
  const { is: isOpened, show, hide } = useModalState();
  const countInsurances = cxCredit.creditInsurances.length;

  const downloadInsurances = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    await cxHttp.downloadInsurances(cxCredit.id, cxCredit.code);
  };

  return (
    <>
      <InitialCard
        left="Insurance"
        right={<RightCircleFilled className={cls.headerIcon} />}
        contentClass={cls.content}
        wrapperClass={cls.cardWrapper}
        onPress={show}
      >
        <div className={cls.wrap}>
          <div className={cls.file}>
            {countInsurances > 0 ? (
              <div className={cls.document}>
                <div className={cls.documentCounter}>{countInsurances}</div>
                <IconButton onClick={downloadInsurances}>
                  <DownloadFileIcon />
                </IconButton>
              </div>
            ) : (
              <div className={cls.documentBlank}>+</div>
            )}
          </div>
          <div className={cls.info}>
            <div className={cls.titleBlock}>Insurance information</div>
            <div className={cls.statusBlock}>
              <Status status={countInsurances ? 'complete' : 'pending'} />
            </div>
          </div>
        </div>
      </InitialCard>

      <Modal
        width={980}
        title={false}
        open={isOpened}
        footer={false}
        onCancel={hide}
        bodyStyle={{ padding: 0 }}
        closable={false}
      >
        {isOpened && <PolicyInsurances cxCredit={cxCredit} />}
      </Modal>
    </>
  );
};

const DownloadFileIcon = () => (
  <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3965_24470)">
      <path d="M23.0808 5.21264H28.8442C28.7719 5.09531 28.6758 4.98931 28.5614 4.89931L22.6557 0.305973C22.496 0.18064 22.303 0.0893066 22.0924 0.0393066L22.1941 4.47664C22.2028 4.88331 22.5961 5.20997 23.0808 5.21264Z"
        fill="#042E6B" />
      <path d="M23.0808 6.316C21.8827 6.31 20.9078 5.50533 20.88 4.50067L20.7767 0H2.958C1.32208 0.004 -0.00158761 1.11867 1.42921e-06 2.49133V29.5087C-0.00158761 30.8813 1.32208 31.996 2.958 32H26.042C27.6779 31.996 29.0016 30.8813 29 29.5087V6.316H23.0808Z"
        fill="#042E6B" />
      <path d="M18.7409 18.6266L16.2084 21.4525C15.9228 21.7708 15.5442 21.9617 15.1418 21.9901C15.1066 21.9953 15.0713 21.9979 15.036 21.9985C15.0054 22.0005 14.9748 22.0005 14.9441 21.9985C14.5412 21.9785 14.1591 21.7948 13.8684 21.4823L11.2936 18.9061C10.9324 18.5463 10.9 17.9272 11.2214 17.5228C11.5428 17.1185 12.096 17.0823 12.4573 17.442L14.1701 19.1467V11.0144C14.1533 10.4735 14.5314 10.0194 15.0152 10.0006C15.4985 9.98184 15.9043 10.405 15.9211 10.9465C15.9216 10.9691 15.9216 10.9917 15.9211 11.0144V19.0115L17.5079 17.2356C17.8512 16.8545 18.4056 16.8565 18.7461 17.2408C19.0866 17.6251 19.0842 18.2455 18.7415 18.6266H18.7409Z"
        fill="#E1E5ED" />
    </g>
    <defs>
      <clipPath id="clip0_3965_24470">
        <rect width="29" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
