import { clsx } from 'clsx';
import { jsPDF } from 'jspdf';
import { Link } from 'react-admin';
import html2canvas from 'html2canvas';
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Container, InitialPage, Loader } from '@components';
import { AutoComplete, Col, Row, Select, Tooltip } from 'antd';
import { FilePdfFilled, InfoCircleFilled } from '@ant-design/icons';

import { Graph } from './Graph';
import { Table } from './Table';
import { useDebounce } from '@hooks';
import { moneyFormatter, unAccent } from '@utils';
import { PaidReportProps, PaidReportsProduct, PaidReportsProps, reportsHttp } from '@network';
import cls from './TraceabilityList.module.css';

interface CreditValue {
  value: string;
  label: string;
}

export const TraceabilityList = () => {
  const emptyCredit: CreditValue = { value: '', label: '' };
  const [isExporting, setExporting] = useState(false);
  const [categoryReport, setCategoryReport] = useState<PaidReportProps[]>([]);
  const [subcategoryReport, setSubcategoryReport] = useState<PaidReportProps[]>([]);
  const [brandReport, setBrandReport] = useState<PaidReportProps[]>([]);
  const [products, setProducts] = useState<PaidReportsProduct[]>([]);
  const [credits, setCredits] = useState<any[]>([]);
  const [partner, setPartner] = useState('');
  const [selectedPartners, setSelectedPartners] = useState<string[]>([]);
  const [user, setUser] = useState('');
  const partnerDeb = useDebounce(partner, 500);
  const userDeb = useDebounce(user, 500);
  const [sorted, setSorted] = useState<'Unit' | 'Price'>('Unit');
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [usersCount, setUsersCount] = useState(0);
  const [usersLink, setUsersLink] = useState('');
  const [ordersCount, setOrdersCount] = useState(0);
  const [ordersLink, setOrdersLink] = useState('');
  const [credit, setCredit] = useState<CreditValue>(emptyCredit);
  const [loading, setLoading] = useState(true);

  const exportPdf = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    setExporting(true);
    await new Promise((resolve) => setTimeout(resolve, 250));
    const div = document.getElementById('export-traceability');

    try {
      div && html2canvas(div, {
        useCORS: false,
      }).then((canvas) => {
        const imgWidth = 212;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        heightLeft -= pageHeight;
        const doc = new jsPDF('p', 'mm');
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= pageHeight;
        }

        doc.save('traceability.pdf');
      });
    } finally {
      setExporting(false);
    }
  };

  const loadNumbers = async () => {
    try {
      const response: PaidReportsProps = await reportsHttp
        .paidProducts({ partner, user, creditId: credit.value, sorted });
      setCategoryReport(response.categoryReport
        .map(i => ({ ...i, totalCost: moneyFormatter.format(+i.totalCost) })));
      setSubcategoryReport(response.subcategoryReport
        .map(i => ({ ...i, totalCost: moneyFormatter.format(+i.totalCost) })));
      setBrandReport(response.brandReport
        .map(i => ({ ...i, totalCost: moneyFormatter.format(+i.totalCost) })));
      setCredits(response.credits);
      setProducts(response.products
        .map(i => ({ ...i, key: i.id.toString() })));
      setSelectedUsers(response.usersAutocomplete);
      setSelectedPartners(response.partnersAutocomplete);
      setUsersCount(response.usersCount);
      setUsersLink(response.usersLink);
      setOrdersCount(response.ordersCount);
      setOrdersLink(response.ordersLink);
    } catch (e) {
      console.error(e);
    }
  };

  const onSearch = (value: string, data: string[], cb: (data: string[]) => void): void => {
    if (value) {
      const list = data
        .filter(item => unAccent(item).toLowerCase()
          .startsWith(unAccent(value).toLowerCase()));
      cb(list);
    } else {
      cb([]);
    }
  };

  const onChange = (value: string, cb: (data: string) => void): void => {
    cb(value);
    setCredit(emptyCredit);
  };

  const onUserClear = () => {
    setUser('');
    setCredit(emptyCredit);
  };

  const onCreditFilter = (value: CreditValue): void => {
    setCredit(value);
  };

  const onSortedFilter = (e: any): void => {
    setSorted(e.value);
  };

  useEffect(() => {
    loadNumbers();
  }, [partnerDeb, userDeb, credit, sorted]);

  useEffect(() => {
    (async () => {
      await loadNumbers();
      setTimeout(() => {
        setLoading(false);
      }, 500);
    })();
  }, []);

  return (
    <div className={cls.wrap}>
      <InitialPage
        title={<>Traceability
          <Tooltip title="Data only from Paid Orders" className={cls.tooltip} overlayClassName={cls.tooltipOver}>
            <InfoCircleFilled className={cls.titleIcon}/>
          </Tooltip>
        </>}
        headerRight={
          <Button
            variant="outlined"
            color="inherit"
            className={clsx(cls.exportBtn)}
            onClick={isExporting ? undefined : exportPdf}
          >
            {isExporting
              ? <Loader mini className={cls.icon} />
              : <><FilePdfFilled className={cls.exportIcon} /> Export PDF</>}
          </Button>
        }
      >
        <Container max>
          <Row gutter={[24, 24]}>
            <Col xs={24} xl={24}>
              <div className={cls.filters}>
                <div className={cls.filter}>
                  <label className={cls.label} htmlFor="partner-filter">Partner</label>
                  <AutoComplete
                    allowClear
                    id="partner-filter"
                    className={cls.autocomplete}
                    onClear={() => setPartner('')}
                    onChange={e => onChange(e, setPartner)}
                    onSearch={e => onSearch(e, selectedPartners, setSelectedPartners)}
                    options={selectedPartners.map(i => ({ label: i, value: i }))}
                  />
                </div>
                <div className={cls.filter}>
                  <label className={cls.label} htmlFor="user-filter">User</label>
                  <AutoComplete
                    allowClear
                    id="user-filter"
                    className={cls.autocomplete}
                    onClear={onUserClear}
                    onChange={e => onChange(e, setUser)}
                    onSearch={e => onSearch(e, selectedUsers, setSelectedUsers)}
                    options={selectedUsers.map(i => ({ label: i, value: i }))}
                  />
                </div>
                <div className={cls.filter}>
                  <label className={cls.label} htmlFor="credit-filter">Credit</label>
                  <Select
                    className={cls.select}
                    onChange={onCreditFilter}
                    value={credit}
                    labelInValue
                    disabled={!credits?.length}
                    allowClear
                    id="credit-filter"
                    options={(credits.length ? credits : []).map(i => ({
                      label: i.code, value: i.id,
                    }))}
                  />
                </div>
                <div className={clsx(cls.filter, cls.filterLinks)}>
                  {usersLink ? <Link to={usersLink} className={cls.link}>
                    Users: {usersCount}
                  </Link> : null}
                  {ordersLink ? <Link to={ordersLink} className={cls.link}>
                    Orders: {ordersCount}
                  </Link> : null}
                </div>
                <div className={cls.filter}>
                  <label className={cls.label} htmlFor="sorted-filter">Sorted By</label>
                  <Select
                    className={cls.select}
                    onChange={onSortedFilter}
                    value={sorted}
                    labelInValue
                    allowClear
                    id="sorted-filter"
                    options={[{ label: 'Unit', value: 'Unit' }, { label: 'Price', value: 'Price' }]}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={[24, 24]} id="export-traceability">
            <Col xs={24} xl={24}>
              <Graph title="Input usage - Categories" id="categories" loading={loading} data={categoryReport} sorted={sorted}/>
            </Col>
            <Col xs={24} xl={24}>
              <Graph title="Input usage - Subcategories" id="subcategories" loading={loading} data={subcategoryReport} sorted={sorted}/>
            </Col>
            <Col xs={24} xl={24}>
              <Graph title="Input usage - Brand" id="brands" loading={loading} data={brandReport} sorted={sorted}/>
            </Col>
            <Col xs={24} xl={24}>
              <Table list={products} loading={loading} />
            </Col>
          </Row>
        </Container>
      </InitialPage>
    </div>
  );
};
