import React from 'react';
import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import { Button } from '@mui/material';
import { SaveButton, SimpleForm, useRedirect } from 'react-admin';

import { CxCredit } from '@types';
import {
  Insurance,
  InvoiceAndCredit,
  MainInfo,
  Receipt,
  Tasks,
  Questions,
  ESGDocs,
  Comments,
  Tabs,
} from '../Components';
import { ConfirmDelete, Container, InitialPage } from '@components';
import cls from './CxWidget.module.css';

interface CxWidgetProps {
  cxCredit: CxCredit;
  editable: boolean;
}

export const CxWidget = ({ cxCredit, editable }: CxWidgetProps) => {
  const title = `CX ${cxCredit.code}`;
  const redirect = useRedirect();

  const handleCancel = () => {
    redirect('/cx/');
  };

  return (
    <InitialPage title={title} backTo="/cx" backText="Back to all records" gap={20}>
      <SimpleForm toolbar={false}>
        <Container max>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24}>
              <MainInfo cxCredit={cxCredit}/>
            </Col>

            <Col xs={24} lg={12} xl={6}>
              <InvoiceAndCredit cxCredit={cxCredit}/>
            </Col>
            <Col xs={24} lg={12} xl={6}>
              <Tasks cxCredit={cxCredit}/>
            </Col>
            <Col xs={24} lg={12} xl={6}>
              <Insurance cxCredit={cxCredit}/>
            </Col>
            <Col xs={24} lg={12} xl={6}>
              <Receipt cxCredit={cxCredit}/>
            </Col>

            {/*<Col xs={24} sm={24}>*/}
            {/*  <TopInfo/>*/}
            {/*</Col>*/}

            <Col xs={24} xl={12}>
              <Questions title="Welcome Calls" source="cx.questionsWelcomeCall"/>
            </Col>
            <Col xs={24} xl={12}>
              <ESGDocs cxCredit={cxCredit}/>
            </Col>

            <Col xs={24} xl={12}>
              <Questions title="Follow Up" source="cx.questionsFollowUp"/>
            </Col>
            <Col xs={24} xl={12}>
              <Comments/>
            </Col>

            <Col xs={24} sm={24}>
              <Tabs
                cxId={cxCredit.id}
                title="Covered Crop"
                source="cx.setsCoveredCrop"
                checkSource="cx.hasCoveredCrop"
              />
            </Col>

            <Col xs={24} sm={24}>
              <Tabs cxId={cxCredit.id} title="Crop Log" source="cx.setsCropLog"/>
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col xs={12} md={8} className={cls.actionsLeft}>
              <ConfirmDelete title="Are you sure?" cb={handleCancel}>
                <Button
                  variant="outlined"
                  color="inherit"
                  className={clsx(cls.action, cls.cancelBtn)}
                  onClick={undefined}
                >Go back</Button>
              </ConfirmDelete>
            </Col>
            <Col xs={12} md={16} className={cls.actionsRight}>
              <SaveButton label="Save" type="button" className={cls.action} />
            </Col>
          </Row>
        </Container>
      </SimpleForm>
    </InitialPage>
  );
};
