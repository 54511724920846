import React from 'react';
import { makeStyles } from '@mui/styles';
import { Row, Col, Input, Select, InputNumber, DatePicker } from 'antd';
import moment from 'moment-timezone';
import IMask from 'imask';

import { CreditCrop, CxCredit } from '@types';
import { FileImage, FilePdf, FileUpload, InitialRow } from '@components';
import { CxOptions, s3http } from '@network';
import { filterOption } from '@utils';
import { Gutter } from 'antd/lib/grid/row';
import Checkbox from '@mui/material/Checkbox';
import cls from '@pages/credit/CreditEdit/CreditInvoices/credit-invoices.module.css';
import { FileXml } from '@components/FileXml';
import { RcFile } from 'antd/lib/upload';
import { FileXlsx } from '@components/FileXlsx';

const DATE_FORMAT = 'DD/MM/YYYY';
const DATE_FORMAT_DB = 'YYYY-MM-DD';
const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: new Date().getFullYear() },
  },
  format: (date: Date) => moment(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date: string) => moment(date, DATE_FORMAT),
  pattern: DATE_FORMAT,
});

interface Props {
  insurance: CreditCrop;
  cxCredit: CxCredit;
  updateInsurance: (insurance: CreditCrop) => void;
  cxOptions: CxOptions;
  idx: number;
}

export const PolicyInsuranceForm = ({
  insurance, cxCredit, updateInsurance, cxOptions, idx,
}: Props) => {
  const cls = useStyles();

  return (
    <div className={cls.container}>
      <div className={cls.cultivo}>
        <Checkbox
          checked={insurance.isProtectedCultivation}
          onChange={e => updateInsurance({
            ...insurance,
            isProtectedCultivation: e.target.checked,
          })}
          size="medium"
        />
        <span>Cultivo Protegido</span>
      </div>

      {insurance.isProtectedCultivation ? (
        <PolicyProtectedForm
          insurance={insurance}
          cxCredit={cxCredit}
          updateInsurance={updateInsurance}
          cxOptions={cxOptions}
          idx={idx}
        />
      ) : (
        <PolicyUnprotectedForm
          insurance={insurance}
          cxCredit={cxCredit}
          updateInsurance={updateInsurance}
          cxOptions={cxOptions}
          idx={idx}
        />
      )}
      key: {insurance.key}, id: {insurance.id}
    </div>
  );
};

const PolicyUnprotectedForm = ({
  insurance, cxCredit, updateInsurance, cxOptions, idx,
}: Props) => {
  const cls = useStyles();
  const rowGutter = [30, 0] as [Gutter, Gutter];

  const uploadAction = async (rcFile: RcFile): Promise<string> => {
    const file = await s3http.uploadFile(rcFile as File);
    updateInsurance({ ...insurance, insuranceFile: file });
    return file.adminUrl;
  };

  return (
    <Row gutter={[35, 10]}>
      <Col sm={18} className={cls.fields}>
        <Row gutter={rowGutter}>
          <Col sm={12}>
            <InitialRow title="Expediente:" labelSize={6}>
              <Input className={cls.formInput} value={cxCredit.code} />
            </InitialRow>
          </Col>
          <Col sm={12}>
            <InitialRow title="Cultivo:" labelSize={6}>
              <Select
                onChange={financedCropId => updateInsurance({ ...insurance, financedCropId })}
                value={insurance.financedCropId}
                className={cls.select}
                showSearch
                optionFilterProp="children"
                filterOption={filterOption}
                options={cxOptions.crops.map(o => ({ value: o.id, label: o.nameMx }))}
              />
            </InitialRow>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col sm={12}>
            <InitialRow title="Acreditado:" labelSize={6}>
              <Input className={cls.formInput}
                value={insurance.accreditedName}
                onChange={e => updateInsurance({ ...insurance, accreditedName: e.target.value })}
              />
            </InitialRow>
          </Col>
          <Col sm={12}>
            <InitialRow title="Monto:" labelSize={6}>
              <InputNumber
                min={0}
                max={1000000000}
                maxLength={13}
                onBeforeInputCapture={e => {
                  const value = e.currentTarget.value;
                  if (value && value.includes('.')) {
                    const [, second] = value.split('.');
                    if (second.length >= 2) {
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  }
                }}
                formatter={(val) => `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(val) => val ? +val.replace(/\$\s?|(,*)/g, '') : 0}
                value={insurance.creditAmount}
                onChange={val => updateInsurance({ ...insurance, creditAmount: val || 0 })}
                className={cls.formInput}
                bordered={false}
                controls={false}
              />
            </InitialRow>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col sm={12}>
            <InitialRow title="Municipio:" labelSize={6}>
              <Input
                className={cls.formInput}
                value={insurance.municipality}
                onChange={e => updateInsurance({ ...insurance, municipality: e.target.value })}
              />
            </InitialRow>
          </Col>
          <Col sm={12}>
            <InitialRow title="Estado:" labelSize={6}>
              <Select
                onChange={stateId => updateInsurance({ ...insurance, stateId })}
                value={insurance.stateId}
                className={cls.select}
                showSearch
                optionFilterProp="children"
                filterOption={filterOption}
                options={cxOptions.states.map(o => ({ value: o.id, label: o.name }))}
              />
            </InitialRow>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col sm={12}>
            <InitialRow title="Superficie total:" labelSize={12}>
              <InputNumber
                min={0}
                max={1000000000}
                maxLength={13}
                prefix={<div className={cls.inputPrefix}>Ha</div>}
                value={insurance.financedHaCx}
                onChange={val => updateInsurance({ ...insurance, financedHaCx: val || 0 })}
                className={cls.formInput}
                bordered={false}
                controls={false}
              />
            </InitialRow>
          </Col>
          <Col sm={12}>
            <InitialRow title="Rendimiento:" labelSize={12}>
              <InputNumber
                min={0}
                max={1000000000}
                maxLength={13}
                prefix={<div className={cls.inputPrefix}>Tons/Ha</div>}
                value={insurance.expectedWeightCx}
                onChange={val => updateInsurance({ ...insurance, expectedWeightCx: val || 0 })}
                className={cls.formInput}
                bordered={false}
                controls={false}
              />
            </InitialRow>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col sm={12}>
            <InitialRow title="Fecha de Siembra:" labelSize={12}>
              <DatePicker
                placeholder="DD/MM/YYYY"
                className={cls.formInput}
                suffixIcon={null}
                allowClear={true}
                value={insurance.sawingDate ? moment(insurance.sawingDate) : null}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  const input = event.target as HTMLInputElement;
                  input.value = MASKED.resolve(input.value);
                  setTimeout(() => {
                    const momentDate = moment(input.value, DATE_FORMAT, true);
                    momentDate.isValid() && updateInsurance({
                      ...insurance,
                      sawingDate: momentDate.format(DATE_FORMAT_DB),
                    });
                  }, 200);
                }}
                onChange={selectedValue => {
                  const momentDate = moment(selectedValue, DATE_FORMAT, true);
                  momentDate.isValid() && updateInsurance({
                    ...insurance,
                    sawingDate: momentDate.format(DATE_FORMAT_DB),
                  });
                }}
                picker="date"
                format={DATE_FORMAT}
              />
            </InitialRow>
          </Col>
          <Col md={12}>
            <InitialRow title="Cosecha Escalonada:" labelSize={12}>
              <Checkbox
                checked={insurance.harvestStepwise}
                onChange={e => updateInsurance({
                  ...insurance,
                  harvestStepwise: e.target.checked,
                })}
                size="medium"
              />
            </InitialRow>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col sm={12}>
            <InitialRow title="Fecha de Cosecha:" labelSize={12}>
              <DatePicker
                placeholder="DD/MM/YYYY"
                className={cls.formInput}
                suffixIcon={null}
                allowClear={true}
                value={insurance.harvestDate ? moment(insurance.harvestDate) : null}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  const input = event.target as HTMLInputElement;
                  input.value = MASKED.resolve(input.value);
                  setTimeout(() => {
                    const momentDate = moment(input.value, DATE_FORMAT, true);
                    momentDate.isValid() && updateInsurance({
                      ...insurance,
                      harvestDate: momentDate.format(DATE_FORMAT_DB),
                    });
                  }, 200);
                }}
                onChange={selectedValue => {
                  const momentDate = moment(selectedValue, DATE_FORMAT, true);
                  momentDate.isValid() && updateInsurance({
                    ...insurance,
                    harvestDate: momentDate.format(DATE_FORMAT_DB),
                  });
                }}
                picker="date"
                format={DATE_FORMAT}
              />
            </InitialRow>
          </Col>
          <Col md={12}>
            <InitialRow title="Sistema de Riego:" labelSize={12}>
              <Input
                className={cls.formInput}
                value={insurance.irrigationSystem}
                onChange={e => updateInsurance({
                  ...insurance,
                  irrigationSystem: e.target.value,
                })}
              />
            </InitialRow>
          </Col>
        </Row>
      </Col>

      <Col sm={6}>
        <div className={cls.document}>
          <div className={cls.documentTitle}>Policy #{idx + 1}</div>
          {!insurance.insuranceFile ? (
            <FileUpload uploadAction={uploadAction} type="pdf" className={cls.upload} />
          ) : (
            <div className={cls.preview}>
              {insurance.insuranceFile?.isPdf && (
                <FilePdf url={insurance.insuranceFile.adminUrl} downloadFilename={
                  `Credit ${cxCredit.code} Policy #${idx + 1}`} />
              )}
              {insurance.insuranceFile?.isXml && (
                <FileXml url={insurance.insuranceFile.adminUrl} downloadFilename={
                  `Credit ${cxCredit.code} Policy #${idx + 1}`} />
              )}
              {insurance.insuranceFile?.isImage && (
                <FileImage url={insurance.insuranceFile.adminUrl} downloadFilename={
                  `Credit ${cxCredit.code} Policy #${idx + 1}`} />
              )}
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

const PolicyProtectedForm = ({
  insurance, cxCredit, updateInsurance, idx,
}: Props) => {
  const cls = useStyles();
  const pos = idx + 1;

  const uploadQuotationFile = async (rcFile: RcFile): Promise<string> => {
    const file = await s3http.uploadFile(rcFile as File);
    updateInsurance({ ...insurance, quotationFile: file });
    return file.adminUrl;
  };

  const uploadInsuranceFile = async (rcFile: RcFile): Promise<string> => {
    const file = await s3http.uploadFile(rcFile as File);
    updateInsurance({ ...insurance, insuranceFile: file });
    return file.adminUrl;
  };

  return (
    <>
      <div className={cls.protected}>
        <div className={cls.protectedText}>
          <span>Para cultivo protegido, completa</span>
          <span className={cls.protectedLink}>este documento</span>
          <span>y súbelo aquí al terminar.</span>
        </div>
      </div>
      <Row gutter={[35, 10]}>
        <Col sm={6}>
          <div className={cls.document}>
            <div className={cls.documentTitle}>Quotation #{pos}</div>
            {!insurance.quotationFile ? (
              <FileUpload uploadAction={uploadQuotationFile}
                type="pdf+xlsx" className={cls.upload} />
            ) : (
              <div className={cls.preview}>
                {insurance.quotationFile?.isPdf && (
                  <FilePdf url={insurance.quotationFile.adminUrl} downloadFilename={
                    `Credit ${cxCredit.code} Quotation #${pos}`} />
                )}
                {insurance.quotationFile?.isXlsx && (
                  <FileXlsx url={insurance.quotationFile.adminUrl} downloadFilename={
                    `Credit ${cxCredit.code} Quotation #${pos}`} />
                )}
                {insurance.insuranceFile?.isXml && (
                  <FileXml url={insurance.quotationFile.adminUrl} downloadFilename={
                    `Credit ${cxCredit.code} Quotation #${pos}`} />
                )}
                {insurance.insuranceFile?.isImage && (
                  <FileImage url={insurance.quotationFile.adminUrl} downloadFilename={
                    `Credit ${cxCredit.code} Quotation #${pos}`} />
                )}
              </div>
            )}
          </div>
        </Col>
        <Col sm={12}>
          Farms
        </Col>
        <Col sm={5}>
          <div className={cls.document}>
            <div className={cls.documentTitle}>Policy #{pos}</div>
            {!insurance.insuranceFile ? (
              <FileUpload uploadAction={uploadInsuranceFile} type="pdf" className={cls.upload} />
            ) : (
              <div className={cls.preview}>
                {insurance.insuranceFile?.isPdf && (
                  <FilePdf url={insurance.insuranceFile.adminUrl} downloadFilename={
                    `Credit ${cxCredit.code} Policy #${pos}`} />
                )}
                {insurance.insuranceFile?.isXml && (
                  <FileXml url={insurance.insuranceFile.adminUrl} downloadFilename={
                    `Credit ${cxCredit.code} Policy #${pos}`} />
                )}
                {insurance.insuranceFile?.isImage && (
                  <FileImage url={insurance.insuranceFile.adminUrl} downloadFilename={
                    `Credit ${cxCredit.code} Policy #${pos}`} />
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

const useStyles = makeStyles({
  container: {},
  protected: {
    margin: '20px 20px 15px',
  },
  protectedText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 15,
    borderBottom: '1px solid #042E6B',
    fontSize: '14px',
    fontWeight: 600,
  },
  protectedLink: {
    color: '#1890FF',
    '&:hover': {
      textDecoration: 'underline',
    },
    margin: '0 6px',
  },
  cultivo: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 20px',
    margin: '0 0 -30px',
  },
  fields: {
    '& .ant-row': {
      marginTop: 5,
      minHeight: 36,
    },
  },
  formInput: {
    border: 'none',
    borderRadius: 0,
    borderBottom: '1px solid rgba(0, 42, 119, 0.10)',
    width: '100%',
    fontSize: '12px !important',
    '&:focus, &:hover, &[class~="ant-input-number-focused"], & [class~="ant-input-number-focused"], &.ant-input-number-affix-wrapper-focused': {
      boxShadow: 'none',
      borderBottom: '1px solid #002A77 !important',
      '& .ant-input-number-input-wrap': {
        border: 'none !important',
      },
    },
    '& .ant-picker-input': {
      border: 'none !important',
    },
  },
  select: {
    width: '100%',
    border: 'none !important',
    backgroundColor: 'transparent !important',
    '& .ant-select-selector': {
      backgroundColor: 'transparent !important',
      border: 'none !important',
      borderBottom: '1px solid rgba(0, 42, 119, 0.10) !important',
      borderRadius: '0 !important',
    },
  },
  inputPrefix: {
    position: 'absolute',
    top: 8,
    right: 0,
  },
  document: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  documentTitle: {
    fontSize: '14px',
    color: '#000',
    marginBottom: 4,
  },
  upload: {
    width: 128,
    height: 164,
  },
  preview: {
    width: 128,
    height: 164,
    border: '2px solid #042E6B',
    borderRadius: '10px',
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
});
