import IconButton from '@mui/material/IconButton';
import { clsx } from 'clsx';

import { PdfIcon } from '@components';
import { FileImage } from './file-image';
import { ConfirmDelete } from '@components/ConfirmDelete';
import cls from './file-controlled.module.css';

interface Props {
  adminUrl: string;
  downloadFilename: string;
  disabled?: boolean;
  onDelete?: () => void;
  isPdf?: boolean;
  wrapClass?: string;
  confirmClass?: string;
  zoom?: boolean;
  fullWidth?: boolean;
  height?: number;
}

export const FileControlled = ({
  adminUrl, downloadFilename, disabled, onDelete, isPdf,
  wrapClass, zoom, fullWidth, height, confirmClass,
}: Props) => {
  const isPdfFile = isPdf || adminUrl.includes('.pdf');

  return (
    <div className={clsx(cls.wrap, wrapClass)} style={{ height }}>
      <div className={clsx(cls.file, fullWidth && cls.fileFullWidth, 'fc-file')}>
        {isPdfFile ? (
          <div className={clsx(cls.pdfBox, 'fc-pdf-box')} title="download">
            <PdfIcon
              url={adminUrl}
              className={clsx(cls.pdfIcon, 'fc-pdf-icon')}
              downloadFilename={downloadFilename}
              downloadCenter
            />
          </div>
        ) : (
          <FileImage
            url={adminUrl}
            className={clsx(zoom ? cls.fileImgZoom : cls.fileImg, 'fc-img')}
            width={86}
            height={105}
            zoom={zoom}
            downloadFilename={downloadFilename}
            disabled={disabled}
          />
        )}
        {!disabled && onDelete ? (
          <IconButton className={clsx(cls.removeIcon, 'fc-remove')}>
            <ConfirmDelete cb={onDelete} title={'Are you sure?'} overlayClassName={confirmClass}>
              <svg xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none">
                <path d="M7 0C3.13461 0 0 3.13461 0 7C0 10.8654 3.13461 14 7 14C10.8654 14 14 10.8654 14 7C13.9938 3.13461 10.8623 0.00310973 7 0Z"
                  fill="#002A77" />
                <path d="M9.63374 4.36293C9.51246 4.24165 9.31655 4.24165 9.19527 4.36293L6.9998 6.5584L4.80433 4.36293C4.68305 4.24165 4.48714 4.24165 4.36586 4.36293C4.24458 4.48421 4.24458 4.68012 4.36586 4.8014L6.56133 6.99687L4.36586 9.19234C4.24458 9.31362 4.24458 9.50953 4.36586 9.63081C4.48714 9.75209 4.68305 9.75209 4.80433 9.63081L6.9998 7.43534L9.19527 9.63081C9.31655 9.75209 9.51246 9.75209 9.63374 9.63081C9.75502 9.50953 9.75502 9.31362 9.63374 9.19234L7.43827 6.99687L9.63374 4.8014C9.75502 4.68012 9.75502 4.48421 9.63374 4.36293Z"
                  fill="white" />
              </svg>
            </ConfirmDelete>
          </IconButton>
        ) : null}
      </div>
    </div>
  );
};
