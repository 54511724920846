import { Http } from './http';
import { CreditCrop, Crop, CxCredit, State, CxSet } from '@types';

class CxHttp extends Http {
  prefix = 'cx';

  async export(queryParams: any): Promise<CxCredit[]> {
    const res = await this.get<CxCredit[]>('/export', {
      params: queryParams,
    });

    return res.data;
  }

  async downloadInsurances(creditId: number, creditCode: string) {
    await this.downloadZip(
      `/${creditId}/download-insurances`,
      `Policy Insurances of Credit ${creditCode}.zip`,
    );
  }

  async getOptions(): Promise<CxOptions> {
    const res = await this.get<CxOptions>('/options');

    return res.data;
  }

  async getInsurances(creditId: number): Promise<CreditCrop[]> {
    const res = await this.get<CreditCrop[]>(`/${creditId}/insurances`);

    return res.data;
  }

  async updateInsurances(creditId: number, insurances: CreditCrop[]): Promise<CxCredit> {
    const res = await this.put<CxCredit>(`/${creditId}/insurances`, { insurances });

    return res.data;
  }

  async partSetsUpdate(id: number, params: any): Promise<CxSet[]> {
    const res = await this.put<CxSet[]>(`/${id}/sets`, params);

    return res.data;
  }
}

export const cxHttp = new CxHttp();

export interface CxOptions {
  crops: Crop[];
  states: State[];
}
