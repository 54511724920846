import React from 'react';
import { EditProps, Edit } from 'react-admin';

import { Forbidden } from '@components';
import { useEditProps, useRbacEdit, useRbacView, useRecord } from '@hooks';
import { CxCredit } from '@types';
import { CxWidget } from './CxWidget';

const Title = () => (<span>CX</span>);

export const CxEdit = (props: EditProps) => {
  const cxCredit = useRecord<CxCredit>(props);
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();

  if (!rbacView) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      {cxCredit && <CxWidget cxCredit={cxCredit} editable={rbacEdit} />}
    </Edit>
  );
};
