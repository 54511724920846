import { useEffect, useState } from 'react';
import { BooleanFieldProps, useRecordContext, useResourceContext } from 'react-admin';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CheckedIcon from '@mui/icons-material/CheckCircle';
import UncheckedIcon from '@mui/icons-material/RemoveCircle';

import { adminHttp } from '@network';

const useStyles = makeStyles({
  box: {
    textAlign: 'center',
  },
  icon: {
    fontSize: 24,
  },
  checked: {
    padding: 0,
    color: '#0C5541',
  },
  unchecked: {
    padding: 0,
    color: '#74706B',
  },
});

interface Props extends BooleanFieldProps {
  disabled?: boolean;
}

export const BooleanField = (props: Props) => {
  const cls = useStyles();
  const record = useRecordContext();
  const resource = useResourceContext();

  const id = record['id'] as number;
  const source = props.source as string;
  const disabled = props.disabled;
  const value = record[source] as boolean | null;

  const [flag, setFlag] = useState(value);

  useEffect(() => {
    const value = record ? (record[source] as boolean | null) : null;
    setFlag(value);
  }, [record]);

  if (!record) {
    return null;
  }

  const swapValue = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const to = !flag;
    await adminHttp.put(`${resource}/${id}`, { [source]: to });
    setFlag(to);
  };

  return (
    <div className={cls.box}>
      {flag === true && (
        <IconButton className={cls.checked} onClick={swapValue} disabled={disabled}>
          <CheckedIcon className={cls.icon} />
        </IconButton>
      )}
      {flag === false && (
        <IconButton className={cls.unchecked} onClick={swapValue} disabled={disabled}>
          <UncheckedIcon className={cls.icon} />
        </IconButton>
      )}
    </div>
  );
};
