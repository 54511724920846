import React, { useEffect, useState } from 'react';
import { useNotify, useRedirect, useRefresh } from 'react-admin';
import { clsx } from 'clsx';
import moment from 'moment-timezone';
import 'moment/locale/es';
import { notification } from 'antd';

import { axiosErrorToString } from '@utils';
import { creditHttp } from '@network';
import { InitialPage } from '@components';
import { Credit, CreditInvoice, CreditInvoiceStatusType, OrderCompositionProduct } from '@types';
import { CreditInvoiceStatus } from '@pages/credit/CreditEdit/CreditInvoices/CreditInvoiceStatus';
import { CreditInvoiceNote } from '@pages/credit/CreditEdit/CreditInvoices/CreditInvoiceNotes';
import { CreditInvoiceUser } from '@pages/credit/CreditEdit/CreditInvoices/CreditInvoiceUser';
import { CreditInvoiceCompany } from '@pages/credit/CreditEdit/CreditInvoices/CreditInvoiceCompany';
import {
  CreditInvoiceProducts,
} from '@pages/credit/CreditEdit/CreditInvoices/CreditInvoiceProducts';
import cls from './credit-invoices.module.css';

const initialFormValues = {
  retailerName: '',
  state: '',
  xmlCert: null,
  xmlDate: null,
  comment: '',
  status: CreditInvoiceStatusType.PENDING,
};

interface FormValues {
  retailerName: string;
  state: string;
  xmlCert: string | null;
  xmlDate: string | null;
  comment: string;
  status: CreditInvoiceStatusType;
}

interface CreditInvoiceDetailProps {
  credit: Credit;
  invoiceId: number;
}

export const CreditInvoicesDetail = ({ credit, invoiceId }: CreditInvoiceDetailProps) => {
  moment.locale('en');

  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();

  const [invoice, setInvoice] = useState<CreditInvoice | null>(null);
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
  const [composition, setComposition] = useState<OrderCompositionProduct[]>([]);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [isIva, setIsIva] = useState<boolean>(false);
  const [invoiceTotal, setInvoiceTotal] = useState<number>(0);

  const date = invoice ? moment(invoice.createdAt).format('DD MMM YYYY, HH:mm') : '';
  const commentedDate = invoice ? moment(invoice.commentedAt).format('DD MMM YYYY, HH:mm') : '';

  const updateValues = (e: any): void => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const updateStatus = (status: CreditInvoiceStatusType): void => {
    setFormValues({
      ...formValues,
      status,
    });
  };

  const submit = async (creditId?: number | undefined) => {
    if (!formValues.state) {
      notification.error({ message: 'State of External Shop is required', placement: 'bottom' });
      return setShowErrors(true);
    }
    if (!formValues.retailerName) {
      notification.error({ message: 'Retailer of External Shop is required', placement: 'bottom' });
      return setShowErrors(true);
    }
    if (composition.length === 0) {
      notification.error({ message: 'Product on the invoice is required', placement: 'bottom' });
      return setShowErrors(true);
    }

    try {
      await creditHttp.updateInvoice(invoiceId, {
        ...formValues,
        composition: composition.map(position => ({
          ...position,
          externalShop: formValues.retailerName,
        })),
        creditId,
        isIva,
      });
      redirect(`/credit/${credit.id}?invoices`);
      refresh();
      notification.success({ message: 'Saved', placement: 'bottom' });
    } catch (e) {
      notify(axiosErrorToString(e));
    }
  };

  useEffect(() => {
    setFormValues({
      retailerName: invoice?.retailerName || '',
      state: invoice?.state || '',
      status: invoice?.status as CreditInvoiceStatusType || CreditInvoiceStatusType.PENDING,
      comment: invoice?.comment || '',
      xmlCert: invoice?.xmlCert || null,
      xmlDate: invoice?.xmlDate || null,
    });
  }, [invoice]);

  useEffect(() => {
    void (async () => {
      try {
        const loadedInvoice = await creditHttp.getInvoiceById(invoiceId);
        setInvoice(loadedInvoice);
        setIsIva(loadedInvoice.isIva);

        const isXml = loadedInvoice.isXml;
        const xmlProducts = loadedInvoice.xmlProducts || [];

        const nextComposition = loadedInvoice.positions.map((position, index) => {
          const invoicePosition: OrderCompositionProduct = {
            id: index,
            productId: position.product.id,
            qty: position.qty,
            productPrice: position.productPrice,
            isExternal: true,
            externalProduct: position.product.name,
            product: position.product,
          };
          if (isXml) {
            const [xmlName, xmlQty, xmlPrice] = (xmlProducts[index] || '').split('|');
            const qty = xmlQty ? +parseFloat(xmlQty).toFixed(3) : 1;
            const productPrice = xmlPrice ? +parseFloat(xmlPrice).toFixed(3) : 0;
            invoicePosition.qty = qty;
            invoicePosition.productPrice = productPrice;
            invoicePosition.xmlName = xmlName;
            invoicePosition.xmlQty = xmlQty;
            invoicePosition.xmlPrice = xmlPrice;
          }
          return invoicePosition;
        });

        if (isXml && xmlProducts.length > 0) {
          for (const xmlProduct of xmlProducts) {
            const [xmlName, xmlQty, xmlPrice] = (xmlProduct || '').split('|');
            const foundXmlPosition = nextComposition.find(pos => pos.xmlName === xmlName);
            if (!foundXmlPosition) {
              const qty = xmlQty ? +parseFloat(xmlQty).toFixed(3) : 1;
              const productPrice = xmlPrice ? +parseFloat(xmlPrice).toFixed(3) : 0;
              const invoicePosition: OrderCompositionProduct = {
                id: nextComposition.length,
                qty: qty,
                productPrice: productPrice,
                xmlName: xmlName,
                xmlQty: xmlQty,
                xmlPrice: xmlPrice,
                isExternal: true,
                productId: null,
              };
              nextComposition.push(invoicePosition);
            }
          }
        }

        setComposition(nextComposition);
      } catch (e) {
        notify(axiosErrorToString(e));
      }
    })();
  }, [invoiceId]);

  const backTo = `/credit/${credit.id}?invoices`;

  if (!invoice) {
    return null;
  }

  return (
    <InitialPage title="Invoice validator" backTo={backTo} backText="Back to Invoice manager">
      <div className={clsx(cls.detail, 'invoice-validator')}>
        <div className={cls.detailCard}>
          <div className={cls.detailCardHead}>
            <div className={cls.detailCardTitle}>Invoice of</div>
            <div className={cls.detailCardDate}>Update {date}</div>
          </div>
          <div className={cls.detailCardContent}>
            <CreditInvoiceUser credit={credit} />
          </div>
        </div>
        <div className={cls.detailCard}>
          <div className={cls.detailCardHead}>
            <div className={cls.detailCardTitle}>External Shop</div>
          </div>
          <div className={cls.detailCardContent}>
            <CreditInvoiceCompany
              retailerName={formValues.retailerName}
              state={formValues.state}
              xmlCert={formValues.xmlCert}
              xmlDate={formValues.xmlDate}
              updateValues={updateValues}
              showErrors={showErrors}
              invoice={invoice}
            />
          </div>
        </div>
        <div className={clsx(cls.detailCard, cls.fullWidth)}>
          <div className={cls.detailCardHead}>
            <div className={cls.detailCardTitle}>On the invoice</div>
          </div>
          <div className={cls.detailCardContent}>
            <CreditInvoiceProducts
              invoice={invoice}
              isIva={isIva}
              setIsIva={setIsIva}
              composition={composition}
              setComposition={setComposition}
              externalShop={formValues.retailerName}
              setInvoiceTotal={setInvoiceTotal}
            />
          </div>
        </div>
        <div className={clsx(cls.detailCard, cls.detailCardNote)}>
          <div className={cls.detailCardHead}>
            <div className={cls.detailCardTitle}>Invoice note</div>
          </div>
          <div className={cls.detailCardContent}>
            <CreditInvoiceNote
              invoice={invoice}
              creditCode={credit.code}
              file={invoice.file}
              date={commentedDate}
              comment={formValues.comment}
              updateValues={updateValues}
            />
          </div>
        </div>
        <div className={cls.detailCard}>
          <CreditInvoiceStatus
            currentStatus={formValues.status as CreditInvoiceStatusType}
            updateStatus={updateStatus}
            submit={submit}
            credit={credit}
            invoiceTotal={invoiceTotal}
          />
        </div>
      </div>
    </InitialPage>
  );
};
