import { clsx } from 'clsx';
import { PropsWithChildren, useRef } from 'react';

import { http } from '@network';
import cls from './GetFileBtn.module.css';

interface Props {
  url?: string;
  fName?: string;
  viewOnly?: boolean;
  className?: string;
  disabled?: boolean;
}

const downloadPdf = async (url: string) => {
  const res = await http.get(url, { responseType: 'blob' });
  const blobResponse = new Blob([res.data as any], { type: 'application/pdf' });
  const blobUrl = URL.createObjectURL(blobResponse);

  return blobUrl;
};

export const GetFileBtn = (
  { url, fName, className, viewOnly = true, disabled = false, children }: PropsWithChildren<Props>,
) => {
  const blob = useRef<string>();

  const onPreview = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!url) return;
    if (!blob.current) {
      blob.current = await downloadPdf(url);
    }
    setTimeout(() => {
      const win = window.open(blob.current, '_blank') as any;
      win.document.title = fName || 'Document preview';
    }, 0);
  };

  const onDownload = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!url) return;
    if (!blob.current) {
      blob.current = await downloadPdf(url);
    }

    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = blob.current;
      link.download = fName || 'Document.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(blob.current);
    }
  };

  return (
    <div
      className={clsx(cls.wrap, className)}
      onClick={disabled ? undefined : viewOnly ? onPreview : onDownload}
    >{children}</div>
  );
};
