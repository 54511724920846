import { Document } from './Document';
import { Admin } from './Admin';
import { Dayjs } from 'dayjs';

export type GuarantorStatus = 'waiting' | 'approved' | 'denied' | 'empty';

export interface Guarantor {
  id: number;
  creditId: number;
  isCompany: boolean;
  status: GuarantorStatus;
  statusEn: string;
  firstName: string;
  lastName: string;
  middleName: string | null;
  fullName: string;
  email: string;
  phone: string;
  fullAddress: string;
  birthdate: string | Dayjs;
  rfc: string;
  marriage: string;
  profession: string;
  relation: string;
  workSince: string;
  isMain: boolean;
  isApproved: boolean;
  isDenied: boolean;
  comment: string | null;
  noFillingCert: string | null;
  commentAdmin: Admin | null;
  documents: Document[];
  type: GuarantorType | null;
  companyShare: number | null;
  location: GuarantorLocation;
}

export interface GuarantorLocation {
  latitude: string;
  longitude: string;
  country: string;
  state: string;
  stateId: number;
  city: string;
  postalCode: string;
  neighbourhood: string;
  street: string;
  house: string;
  apartment: string;
  extended: string;
}

export enum GuarantorType {
  Apoderado = 'Apoderado',
  Accionista = 'Accionista',
}

export const guarantorTypes = [GuarantorType.Apoderado, GuarantorType.Accionista];

