import React from 'react';
import { clsx } from 'clsx';
import { Col, Row } from 'antd';

import { CxCredit } from '@types';
import { dateFormatter } from '@utils';
import { InitialCard } from '@components';
import { UserContacts } from '@pages/user/UserInitial/user-contacts';
import cls from './MainInfo.module.css';

interface Props {
  cxCredit: CxCredit;
}

export const MainInfo = ({ cxCredit }: Props) => {
  return (
    <InitialCard mb={12}>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12}>
          <div className={cls.row}>
            <div className={cls.col}>{cxCredit.code}</div>
            <div className={cls.col}>{cxCredit.user.fullName}</div>
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className={clsx(cls.row, cls.rowLast)}>
            <div className={cls.col}>
              Payment Date: {cxCredit.paymentAt ? dateFormatter.toDateSlash(cxCredit.paymentAt) : '---'}
            </div>
            <div className={cls.col}>
              <UserContacts user={cxCredit.user} showTooltip className={cls.userContacts} />
            </div>
          </div>
        </Col>
      </Row>
    </InitialCard>
  );
};
