import { Http } from './http';
import { Address, User } from '@types';

class UserHttp extends Http {
  prefix = 'user';

  async loginAdmin(userId: number): Promise<string> {
    const res = await this.get<string>(`/${userId}/login-admin`);

    return res.data;
  }

  async updatePartially(userId: number, payload: Record<string, any>): Promise<User> {
    const { data: user } = await this.put<User>(`/${userId}/partial`, payload);

    return user;
  }

  async updateIsCompany(userId: number, isCompany: boolean): Promise<User> {
    return await this.updatePartially(userId, { isCompany });
  }

  async updateAddressById(params: UserAddressUpdateById) {
    const res = await this.put(`/update-address/${params.location.id}`, params);

    return res.data;
  }

  async updateBulk(params: UpdateBulkUsersParams): Promise<number> {
    const { data } = await this.put<number>('/update-bulk', params);

    return data;
  }

  async addAddress(params: UserAddressAdd) {
    const res = await this.post('/add-address', params);

    return res.data;
  }

  async removeAddress(addressId: number) {
    const res = await this.delete(`/remove-address/${addressId}`);

    return res.data;
  }

  async getUserSmsCode(userId: number): Promise<string> {
    const res = await this.get<string>(`/login-code/${userId}`);

    return res.data;
  }

  async setContactNextCycle(params: SetContactNextCycle) {
    const { userId, contactNextCycle } = params;
    const res = await this.put<boolean>(`/setContactNextCycle/${userId}`, { contactNextCycle });

    return res.data;
  }

  async inactivateByLogins() {
    const res = await this.get<string>('/inactivate-by-logins');

    return res.data;
  }

  async addBulkActivation(ids: number[], activationId: number) {
    const res = await this.post<void>('/add-bulk-activation', { ids, activationId });

    return res.data;
  }

  async removeActivation(id: number) {
    const res = await this.patch<void>(`/${id}/remove-activation`);

    return res.data;
  }

  async getAll(query: string, count = 0) {
    const res = await this.get<User[]>(`/?_start=0&_end=${count}&q=${query}&_sort=fullName`);

    return res.data;
  }

  async getSources(): Promise<UserChannel[]> {
    const res = await this.get<UserChannel[]>('/sources');

    return res.data;
  }

  async resendEmailActivateLink(userId: number): Promise<number> {
    const res = await this.post<number>(`/resend-email-activate-link/${userId}`);

    return res.data;
  }
}

export const userHttp = new UserHttp();

interface UpdateBulkUsersParams {
  userIds: number[];
  cropIds?: number[];
  stateId?: number;
  managerId?: number;
  activationId?: number;
  gender?: string;
  marriage?: string;
  inactiveReason?: string;
  userStatus?: string;
  adminNote?: string;
  reminderDate?: string;
  reminderTime?: string;
}

export interface UserStatusChartResponse {
  period: string;
  deactivated: number;
  registered: number;
  info_filled: number;
  questionnaire_filled: number;
  docs_sent: number;
  renewed: number;
}

interface UserAddressUpdate {
  userId: number;
  locations: Address[];
}

interface UserAddressAdd {
  userId: number;
  location: Partial<Address>;
}

interface UserAddressUpdateById {
  location: Partial<Address>;
}

export interface PerformanceResultDto {
  newUsers: number;
  performance: PerformanceUsersChartDto[];
}

interface PerformanceUsersChartDto {
  period: string;
  registered: number;
  docs_sent: number;
}

interface SetContactNextCycle {
  userId: number;
  contactNextCycle: string | null;
}

export interface UserChannel {
  id: string;
  name: string;
}
