import { clsx } from 'clsx';
import { RightCircleFilled } from '@ant-design/icons';

import { CxCredit } from '@types';
import { moneyFormatter } from '@utils';
import { InitialCard } from '@components';
import cls from './InvoiceAndCredit.module.css';

interface Props {
  cxCredit: CxCredit;
}

export const InvoiceAndCredit = ({ cxCredit }: Props) => {
  return (
    <InitialCard
      left="Invoice and Credit Manager"
      right={<RightCircleFilled className={cls.headerIcon} />}
      link={`/credit/${cxCredit.id}?invoices`}
      contentClass={cls.content}
      wrapperClass={cls.cardWrapper}
    >
      <div className={cls.wrap}>
        <div className={cls.title}>Credit</div>
        <div className={cls.value}>{moneyFormatter.format(cxCredit.bankApproval || 0)}</div>
        <div className={cls.title}>Balance</div>
        <div className={clsx(cls.value, cls.last)}>
          {moneyFormatter.format(cxCredit.balance || 0)}
        </div>
      </div>
    </InitialCard>
  );
};
