import { EditProps, Edit, SimpleForm, BooleanInput } from 'react-admin';
import {
  Row,
  Col,
  Container,
  TextInput,
  Forbidden,
  DateTimeInput,
} from '@components';
import { useEditProps, useRbacEdit, useRbacView } from '@hooks';

const Title = ({ record }: {record?: any}) =>
  record ? (<span>Division: {record.nameMx}</span>) : null;

export const DivisionEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();

  if (!rbacView) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <SimpleForm toolbar={rbacEdit ? undefined : false}>
        <Container max>
          <Row>
            <Col><TextInput source="name" label="Category" required /></Col>
            <Col><TextInput source="synonym" label="URL" disabled /></Col>
            <Col><DateTimeInput source="updatedAt" disabled /></Col>
            <Col><TextInput source="id" label="ID" /></Col>
          </Row>
          <Row>
            <Col><BooleanInput source="noTraceability" label="Excluded from Traceability" /></Col>
          </Row>
        </Container>
      </SimpleForm>
    </Edit>
  );
};
