import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Select } from 'antd';
import { useRequest } from '@hooks';
import { useNotify } from 'react-admin';
import { useListContext } from 'ra-core';

import { productHttp, productInvoiceHttp } from '@network';
import { BlueButton, BlueOutlineButton, InputSelect } from '@components';
import { useModalContext } from '@providers';

enum BulkProps {
  'Brand', // dropdown
  'Category', // dropdown
  'Subcategory', // dropdown
  'Enable', // boolean
}

type BulkProp = keyof typeof BulkProps;

const bulkPropsList = Object.keys(BulkProps).filter(k => isNaN(+k) || isNaN(parseFloat(k)));

export const ProductListActions = () => {
  const notify = useNotify();
  const cls = useStyles();
  const { hideModal } = useModalContext();
  const { selectedIds, refetch } = useListContext();
  const [prop, setProp] = useState<BulkProp>();
  const [value, setValue] = useState<string | number | null>(null);

  const [brands, setBrands] = useState<FormSelectOption[]>([]);
  const [categories, setCategories] = useState<FormSelectOption[]>([]);
  const [subCategories, setSubCategories] = useState<FormSelectOption[]>([]);

  useEffect(() => {
    void (async () => {
      try {
        const fetchCategories = productInvoiceHttp.getCategories();
        const fetchSubCategories = productInvoiceHttp.getSubCategories();
        const fetchBrands = productInvoiceHttp.getBrands();
        const [loadedCategories, loadedSubCategories, loadedBrands] =
          await Promise.all([fetchCategories, fetchSubCategories, fetchBrands]);

        setCategories(loadedCategories?.map((i: any) => ({ id: i.id, name: i.name })) || []);
        setSubCategories(loadedSubCategories?.map((i: any) => ({ id: i.id, name: i.name })) || []);
        setBrands(loadedBrands?.map((i: any) => ({ id: i.id, name: i.name })) || []);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    console.log('...value', value);
  }, [value]);

  useEffect(() => {
    resetValues();
    prop && setTimeout(() => {
      // focus
    }, 300);
  }, [prop]);

  const resetValues = () => {
    setValue(null);
  };

  const onCancel = () => {
    setProp(undefined);
    resetValues();
    hideModal();
  };

  const { loading, submit } = useRequest(async () => {
    if (!Array.isArray(selectedIds) || selectedIds.length === 0) {
      return notify('No records are selected');
    }
    if (!prop) {
      return notify('No property is selected');
    }

    const updatedTotal = await productHttp.updateBulk({ selectedIds, prop, value });

    notify(`Updated records: ${updatedTotal}`);
    onCancel();
    setTimeout(() => refetch(), 300);
  });

  return (
    <div className={cls.box}>
      <div className={cls.title}>Properties to update</div>
      <Select value={prop} onChange={v => setProp(v)} className={cls.select}
        placeholder="Select a property to edit">
        {bulkPropsList.map(propName => (
          <Select.Option key={propName} value={propName}>
            {propName}
          </Select.Option>
        ))}
      </Select>

      <div className={cls.content}>
        <div className={cls.title}>{prop}</div>

        {prop === 'Brand' ? (
          <InputSelect
            name="brand"
            label="Brand options"
            onChange={e => setValue(e.target.value)}
            selectedValue={value}
            values={brands.map(b => b.name)}
          />
        ) : null}

        {prop === 'Category' ? (
          <InputSelect
            name="category"
            label="Category options"
            onChange={e => setValue(e.target.value)}
            values={categories.map(b => b.name)}
            selectedValue={value}
          />
        ) : null}

        {prop === 'Subcategory' ? (
          <InputSelect
            name="subcategory"
            label="Subategory options"
            onChange={e => setValue(e.target.value)}
            values={subCategories.map(b => b.name)}
            selectedValue={value}
          />
        ) : null}

        {prop === 'Enable' ? (
          <InputSelect
            name="enable"
            label="Enable *"
            onChange={e => setValue(e.target.value)}
            values={['Enable', 'Disable']}
            selectedValue={value}
          />
        ) : null}
      </div>

      <div className={cls.controls}>
        <BlueButton className={cls.btn} onClick={submit} disabled={loading}>
          Update
        </BlueButton>
        <BlueOutlineButton className={cls.btn} onClick={onCancel}>
          Cancel
        </BlueOutlineButton>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  box: {},
  group: {
    marginTop: 15,
  },
  title: {
    fontSize: 20,
    lineHeight: 1,
    fontWeight: 500,
  },
  select: {
    marginTop: 10,
    width: '100%',
    borderRadius: 8,
    border: '1px solid #E0E7EE',
    backgroundColor: '#F3F7F9',
    '& .ant-select-selector': {
      backgroundColor: 'transparent !important',
    },
  },
  content: {
    marginTop: 15,
  },
  controls: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    margin: '0 7px',
    padding: '5px 29px !important',
    height: 34,
    borderRadius: 8,
    textTransform: 'none',
  },
  textarea: {
    marginTop: 10,
    padding: '10px 19px 12px',
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#E1E5ED',
    '& input': {
      background: '#E1E5ED',
    },
    '& .ant-picker-input': {
      paddingLeft: 10,
      paddingRight: 10,
      background: '#E1E5ED',
      borderRadius: 8,
      border: '1px solid #042E6B',
    },
  },
  inputIcon: {
    color: '#333',
    marginRight: 10,
  },
});

interface FormSelectOption {
  id: number | string;
  name: string;
}
