import {
  FunctionField,
  ListProps,
  TextField,
  ReferenceField,
  NumberField,
  Identifier,
  Link,
  RaRecord,
  useRedirect,
  RowClickFunction,
} from 'react-admin';
import React from 'react';

import {
  MoneyField,
  ReferenceInput,
  SelectInput,
  Grid,
  Forbidden,
  TextInput,
  SelectArrayInput,
  NullableBooleanInput,
  DateRangeSelect,
  BooleanField,
  DateField,
} from '@components';
import { checkSuperadmin } from '@providers';
import { useExporter, useRbacEdit, useRbacList } from '@hooks';
import { Credit, genderOptions } from '@types';
import { ButtonLinkField } from '@components/fields/ButtonLinkField';
import { GridList } from '@components/GridList/GridList';
import { creditStatusOptions, CreditStatusField } from './CreditStatusField';
import { CreditStatesFilter } from './CreditStatesFilter';
import { CreditCropsFilter } from './CreditCropsFilter';
import { CreditListActions } from './CreditListActions';
import { RiskScoreField } from '@pages/credit/RiskScoreField';
import { formatHoursToPhrase } from '@utils';

export const CreditList = (props: ListProps) => {
  const exporter = useExporter({
    filename: 'Verqor Credits',
    props: {
      'position': {
        label: 'Position',
      },
      'id': {
        label: 'ID',
      },
      'code': {
        label: 'Code',
      },
      'status': {
        label: 'Status',
        prop: 'statusEn',
      },
      'accreditedName': {
        label: 'Accredited',
      },
      'user.phone': {
        label: 'Phone',
        transform: 'phone',
      },
      'user.email': {
        label: 'Email',
      },
      'user.address': {
        label: 'Address',
        prop: 'user.address',
      },
      'user.sourceEn': {
        label: 'Channel',
        prop: 'user.sourceEn',
      },
      'userIpAddress': {
        label: 'User IP address',
        prop: 'userIpAddress',
      },
      'user.marriage': {
        label: 'Civil status',
      },
      'user.stateId': {
        label: 'State',
        prop: 'user.state.name',
      },
      'user.responsibleAdminId': {
        label: 'Responsible Manager',
        prop: 'user.responsibleAdmin.name',
      },
      'invoicesTitle': {
        label: 'Invoices',
      },
      'bankId': {
        label: 'Bank',
        prop: 'bank.nameMx',
      },
      'user.hubspotLink': {
        label: 'Hubspot',
        prop: 'user.hubspotLink',
      },
      'docsLink': {
        label: 'DocsLink',
      },
      'progressAt': {
        label: 'Update by client',
        transform: 'datetime',
      },
      'progressPercent': {
        label: 'File progress',
      },
      'modificationAt': {
        label: 'Last update',
        transform: 'datetime',
      },
      'bankApproval': {
        label: 'Credit',
        transform: 'money',
      },
      'balance': {
        label: 'Balance',
        transform: 'money',
      },
      'requestAmount': {
        label: 'Request Amount',
        transform: 'money',
      },
      'farmerDeposit': {
        label: 'Farmer Deposit',
        transform: 'money',
      },
      'membership': {
        label: 'Membership',
        transform: 'money',
      },
      'creditFee': {
        label: 'Withdrawal Fee',
        transform: 'money',
      },
      'insurance': {
        label: 'Insurance',
        transform: 'money',
      },
      'refunds': {
        label: 'Refunds',
        transform: 'money',
      },
      'ordersTotalAmount': {
        label: 'Total Orders',
        transform: 'money',
      },
      'retention': {
        label: 'Retention',
        transform: 'money',
      },
      'repayment': {
        label: 'Repayment',
        transform: 'money',
      },
      'interest': {
        label: 'Interests',
        transform: 'money',
      },
      'paymentAmount': {
        label: 'Payment Amount',
        transform: 'money',
      },
      'creditCropNames': {
        label: 'Financed Crops',
      },
      'financedHa': {
        label: 'Financed Ha',
      },
      'buyerNames': {
        label: 'Buyers',
      },
      'retailerNames': {
        label: 'Retailers',
      },
      'associateNames': {
        label: 'Associates',
      },
      'insuranceCompany': {
        label: 'Insurance Company',
      },
      'cropProblems': {
        label: 'Crop Problems',
      },
      'signatureAt': {
        label: 'Signature At',
        transform: 'date',
      },
      'paymentAt': {
        label: 'Payment At',
        transform: 'date',
      },
      'createdAt': {
        label: 'Created At',
        transform: 'date',
      },
      'isFixed': {
        label: 'Is Fixed',
        transform: 'boolean',
      },
      'parDelayFixed': {
        label: 'PAR default',
        transform: 'delay',
      },
      'parDelayDays': {
        label: 'Delay Days',
      },
      'rejectReason': {
        label: 'Reason for rejection',
      },
      'riskRejectReason': {
        label: 'Risk rejection reason',
      },
      'statusBeforeRejection': {
        label: 'Status before rejection',
        transform: 'status',
      },
      'statusChangedAt': {
        label: 'Last Status Change',
        transform: 'datetime',
      },
      'riskScore': {
        label: 'Score',
      },
    },
  });
  const isSuperadmin = checkSuperadmin();
  const rbacList = useRbacList();
  const rbacEdit = useRbacEdit();
  const redirect = useRedirect();

  if (!rbacList) {
    return <Forbidden />;
  }

  const creditRowClick: RowClickFunction = (id: Identifier, resource: string, record: RaRecord) => {
    redirect(`/${resource}/${id}?initial`);
    return false;
  };

  return (
    <GridList {...props}
      editComponent={rbacEdit ? <CreditListActions /> : undefined}
      exporter={exporter}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Grid
        rowClick={creditRowClick}
        rowStyle={(record: any) => record.unvisited ? { border: 'transparent' } : {}}
        sx={{
          '& .RaDatagrid-row[style="border: transparent;"] .MuiTableCell-paddingCheckbox': {
            backgroundColor: '#647191',
          },
        }}
        defaultColumns={'code status accreditedName user.phone user.hubspotLink bankApproval balance requestAmount riskScore'.split(' ')}
      >
        <FunctionField source="code" sortBy="code" render={(record: any) => (
          <Link to={`/credit/${record.id}?initial`}>
            <TextField
              source="code"
              label="Credit code"
              style={{ fontSize: 13, display: 'inline-block', width: 75, textAlign: 'center' }}
            />
          </Link>
        )} />
        <CreditStatusField source="status" />
        <FunctionField
          source="accreditedName"
          label="Accredited"
          render={(record: any) => (
            <Link to={`/credit/${record.id}?initial`}>
              <TextField
                source="accreditedName"
                label="Accredited"
                style={{ display: 'inline-block', width: 210 }}
              />
            </Link>
          )}
        />
        <TextField source="user.phone" label="Phone" />
        <TextField source="user.email" label="Email" />
        <TextField source="user.address" label="Address" sortable={false} />
        <TextField source="userIpAddress" label="User IP address" sortable={false} />
        <TextField source="user.marriage" label="Civil status" />
        <TextField source="user.sourceEn" label="Channel" />
        <TextField source="user.contactOwner.name" label="Owner" />
        {isSuperadmin ? (
          <ReferenceField reference="state" source="user.stateId" label="State">
            <TextField source="name" />
          </ReferenceField>
        ) : (
          <TextField source="user.state.name" label="State" />
        )}
        {isSuperadmin ? (
          <ReferenceField
            reference="admin"
            source="user.responsibleAdminId"
            label="Responsible manager"
          >
            <TextField source="name" />
          </ReferenceField>
        ) : (
          <TextField source="user.responsibleAdmin.name" label="Responsible manager" />
        )}
        <TextField source="invoicesTitle" label="Invoices" />
        {isSuperadmin ? (
          <ReferenceField reference="bank" source="bankId" label="Bank">
            <TextField source="nameMx" />
          </ReferenceField>
        ) : (
          <TextField source="bank.nameMx" label="Bank" />
        )}
        <ButtonLinkField source="user.hubspotLink" text="Hubspot" label="Hubspot" center />
        <ButtonLinkField source="docsLink" text="DocsLink" />
        <DateField source="progressAt" label="Update by client" showTime />
        <TextField source="progressPercent" label="File progress" />
        <DateField source="modificationAt" label="Last update" showTime />
        <MoneyField source="bankApproval" label="Credit"
          style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="balance" style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="requestAmount" style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="farmerDeposit" label="Farmer Deposit" />
        <MoneyField source="membership" label="Membership" />
        <MoneyField source="creditFee" label="Withdrawal fee" />
        <MoneyField source="insurance" label="Insurance" />
        <MoneyField source="refunds" label="Refunds" />
        <MoneyField source="ordersTotalAmount" label="Total Orders" />
        <MoneyField source="retention" label="Retention" />
        <MoneyField source="repayment" label="Repayment" />
        <MoneyField source="interest" label="Interests" />
        <MoneyField source="paymentAmount" label="Payment Amount" />
        <TextField source="creditCropNames" label="Financed Crops" />
        <NumberField source="financedHa" label="Financed Ha" />
        <TextField source="buyerNames" label="Buyers" sortable={false} />
        <TextField source="retailerNames" label="Retailers" sortable={false} />
        <TextField source="associateNames" label="Associates" sortable={false} />
        <TextField source="insuranceCompany" label="Insurance company" />
        <TextField source="cropProblems" label="Crop problems" />
        <DateField source="signatureAt" label="Signature At" />
        <DateField source="paymentAt" label="Payment At" />
        <DateField source="createdAt" label="Created At" showTime />
        <BooleanField source="isFixed" label="Is Fixed" />
        <TextField source="parDelayFixed" label="PAR default" />
        <TextField source="parDelayDays" label="Delay Days" />
        <TextField source="rejectReason" label="Reason for rejection" />
        <TextField source="riskRejectReason" label="Risk rejection reason" sortable={false} />
        <CreditStatusField source="statusBeforeRejection" label="Status before rejection" />
        <DateField source="statusChangedAt" label="Last Status Change" showTime />
        <RiskScoreField source="riskScore" label="Score" />
        <FunctionField
          source="hoursCreatedAnalysis"
          label="Applying to Analysis"
          render={(record: any) => {
            const credit = record as Credit;

            return (
              <div>{formatHoursToPhrase(credit?.hoursCreatedAnalysis)}</div>
            );
          }}
        />
        <FunctionField
          source="hoursAnalysisResult"
          label="Analysis to Approved or Denied"
          render={(record: any) => {
            const credit = record as Credit;

            return (
              <div>{formatHoursToPhrase(credit?.hoursAnalysisResult)}</div>
            );
          }}
        />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput key="q" source="q" label="Search" alwaysOn style={{ width: 200 }} />,
  <TextInput key="accreditedName" source="accreditedName" label="Accredited"
    style={{ width: 220 }} />,
  <SelectArrayInput
    key="status"
    source="status"
    choices={creditStatusOptions}
    className="MS-field"
    style={{ width: 250 }}
    alwaysOn
  />,
  <TextInput key="sourceEn" source="sourceEn" label="Channel" style={{ width: 200 }} />,
  <ReferenceInput
    key="contactOwnerId"
    source="contactOwnerId"
    reference="admin"
    label="Owner"
    allowEmpty
    perPage={10000}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <SelectInput optionText="name" label="Owner" />
  </ReferenceInput>,
  <ReferenceInput
    key="bankId"
    source="bankId"
    reference="bank"
    label="Bank"
    allowEmpty
    perPage={10000}
    sort={{ field: 'nameMx', order: 'ASC' }}
  >
    <SelectInput optionText="nameMx" />
  </ReferenceInput>,
  <ReferenceInput
    key="responsibleAdminId"
    source="responsibleAdminId"
    reference="admin/managers"
    label="Responsible Manager"
    perPage={10000}
    sort={{ field: 'name', order: 'ASC' }}
    emptyText="-"
  >
    <SelectInput optionText="name" label="Responsible Manager" style={{ width: 100 }} />
  </ReferenceInput>,
  <DateRangeSelect
    key="createdAt"
    source="createdAt"
    label="Created/Signature/Payment/Last update"
    alwaysOn
    choices={[
      { id: 'createdAt', name: 'Created At' },
      { id: 'signatureAt', name: 'Signature At' },
      { id: 'paymentAt', name: 'Payment At' },
      { id: 'modificationAt', name: 'Last update' },
      { id: 'progressAt', name: 'Update by client' },
      { id: 'statusChangedAt', name: 'Last status change' },
    ]}
  />,
  <CreditStatesFilter key="state" source="state" label="State" />,
  <SelectInput
    key="progress"
    source="progress"
    label="File progress"
    choices={[
      { id: 'completed', name: 'Completed file' },
      { id: 'incomplete', name: 'Incomplete files' },
    ]}
    style={{ width: 200 }}
  />,
  <SelectInput
    key="invoices"
    source="invoices"
    label="Invoices"
    choices={[
      { id: 'uploaded', name: 'With invoices' },
      { id: 'empty', name: 'Not uploaded' },
    ]}
    style={{ width: 200 }}
  />,
  <SelectInput key="gender" source="gender" choices={genderOptions} />,
  <CreditCropsFilter key="crops" source="crops" label="Financed crops" />,
  <TextInput key="creditCodes" source="creditCodes" clearAlwaysVisible />,
  <NullableBooleanInput key="unvisited" source="unvisited" label="Unread credits" />,
  <NullableBooleanInput key="isFixed" source="isFixed" label="Is Fixed" />,
  <SelectInput
    key="delayPar"
    source="delayPar"
    label="PAR default"
    choices={[
      { id: '0', name: '0' },
      { id: 'Default', name: 'Default' },
      { id: '90', name: '90' },
      { id: '60', name: '60' },
      { id: '30', name: '30' },
      { id: 'Recovered', name: 'Recovered' },
    ]}
    style={{ width: 120 }}
  />,
  <NullableBooleanInput key="isDemo" source="isDemo" label="Show Demo" />,
];
