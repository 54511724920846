import React from 'react';
import { ListProps, TextField, ReferenceField } from 'react-admin';

import {
  TextInput,
  Grid,
  GridList,
  Forbidden,
  SelectArrayInput,
  DateField,
  PhoneField,
  MoneyField,
  NullableBooleanInput,
  DateRangeSelect,
} from '@components';
import { useExporter, useRbacList } from '@hooks';
import { DonePendingField } from './DonePendingField';
import { CreditStatusField, cxStatusOptions } from '@pages/credit/CreditStatusField';
import { exportToCsv, moneyFormatter } from '@utils';
import { cxHttp } from '@network';
import { CxCredit } from '@types';

export const CxList = (props: ListProps) => {
  const rbacList = useRbacList();
  const exporter = useExporter({
    filename: 'CX',
    props: {
      'code': {
        label: 'Code',
      },
      'user.fullName': {
        label: 'Full Name',
      },
      'status': {
        label: 'Status',
        prop: 'statusEn',
      },
      'accreditedName': {
        label: 'Accredited',
      },
      'user.phone': {
        label: 'Phone',
        transform: 'phone',
      },
      'signatureAt': {
        label: 'Signature Date',
        transform: 'date',
      },
      'paymentAt': {
        label: 'Payment Date',
        transform: 'date',
      },
      'bankApproval': {
        label: 'Credit Amount',
        transform: 'money',
      },
      'balance': {
        label: 'Balance',
        transform: 'money',
      },
      'cx.hasFarms': {
        label: 'Farms',
        transform: 'done',
      },
      'cx.hasWelcomeCall': {
        label: 'Welcome Call',
        transform: 'done',
      },
      'cx.hasFollowUp': {
        label: 'Follow Up',
        transform: 'done',
      },
      'cx.hasCoveredCrop': {
        label: 'Covered Crop',
        transform: 'done',
      },
    },
  });

  if (!rbacList) {
    return <Forbidden />;
  }

  const onExport = async (queryParams: any) => {
    const positions = await cxHttp.export(queryParams);

    const mappingFuncWelcomeCall = (item: CxCredit) => {
      const itemExport = {
        'Credit Code': item.code,
        'Accredited': item.accredited ?? '',
        'Credit Status': item.statusEn ?? '',
      };
      for (const question of item.cx.questionsWelcomeCall) {
        itemExport[question.name] = question.answer || '';
      }
      return itemExport;
    };
    exportToCsv(positions, 'CX Welcome Call', mappingFuncWelcomeCall);

    const mappingFuncFollowUp = (item: CxCredit) => {
      const itemExport = {
        'Credit Code': item.code,
        'Accredited': item.accreditedName ?? '',
        'Credit Status': item.statusEn ?? '',
      };
      for (const question of item.cx.questionsFollowUp) {
        itemExport[question.name] = question.answer || '';
      }
      return itemExport;
    };
    exportToCsv(positions, 'CX Follow Up', mappingFuncFollowUp);
  };

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      hideRemoveButton
      exporter={exporter}
      onExport={onExport}
    >
      <Grid
        rowClick="edit"
        defaultColumns={['code', 'status', 'accreditedName', 'user.phone', 'balance',
          'cx.hasFarms', 'cx.hasWelcomeCall', 'cx.hasFollowUp',
        ]}
      >
        <TextField source="code" label="Credit Code" />
        <ReferenceField source="userId" reference="user" label="Full name">
          <TextField source="fullName" />
        </ReferenceField>
        <CreditStatusField source="status" label="Status" />
        <TextField source="accreditedName" label="Accredited" />
        <PhoneField source="user.phone" label="Phone" />
        <DateField source="signatureAt" label="Signature Date" />
        <DateField source="paymentAt" label="Payment Date" />
        <MoneyField source="bankApproval" label="Credit Amount"
          style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="balance" label="Balance"
          style={{ display: 'inline-block', width: 100 }} />
        <DonePendingField source="cx.hasFarms" label="Farms" />
        <DonePendingField source="cx.hasWelcomeCall" label="Welcome Call" />
        <DonePendingField source="cx.hasFollowUp" label="Follow Up" />
        <DonePendingField source="cx.hasCoveredCrop" label="Covered Crop" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" label="Search" alwaysOn style={{ width: 200 }} />,
  <SelectArrayInput
    key="status"
    source="status"
    choices={cxStatusOptions}
    className="MS-field"
    style={{ width: 230 }}
    alwaysOn
  />,
  <DateRangeSelect
    key="createdAt"
    source="createdAt"
    label="Created/Signature/Payment/Last update"
    alwaysOn
    choices={[
      { id: 'signatureAt', name: 'Signature Date' },
      { id: 'paymentAt', name: 'Payment Date' },
    ]}
  />,
  <NullableBooleanInput key="hasFarms" source="hasFarms" label="Farms"
    trueLabel="Done" falseLabel="Pending" />,
  <NullableBooleanInput key="hasWelcomeCall" source="hasWelcomeCall" label="Welcome Call"
    trueLabel="Done" falseLabel="Pending" />,
  <NullableBooleanInput key="hasFollowUp" source="hasFollowUp" label="Follow Up"
    trueLabel="Done" falseLabel="Pending" />,
  <NullableBooleanInput key="hasCoveredCrop" source="hasCoveredCrop" label="Covered Crop"
    trueLabel="Done" falseLabel="Pending" />,
];
